<template>
  <section>
    <video
      autoplay
      loop
      ref="video"
      @click.stop="goBack"
      :src="videoSource">
    </video>
  </section>
</template>

<script>
import verge from "verge";
import videoSourceChooser from "@/helpers/video-src-chooser";

export default {
  computed: {
    viewportW: () => verge.viewportW(),

    videoSource: videoSourceChooser
  },

  methods: {
    goBack: function() {
      this.$router.go(-1);
    }
  },

  activated: function() {
    this.$refs.video.play();
  }
}
</script>

<style scoped>
section {
  position: absolute;
  height: 100vh;
  width: 100vh;
  background: #121212;
  top: 0;
  left: 0;
  z-index: 100;
  margin-top: 0;
}

video {
  position: absolute;
  left: 0;
  width: 100vw;
  min-width: 100%;
  height: 100vh;
  object-fit: fill;
  height: calc(100vh - 128px);
  top: max(64px, calc(50vh - 62vw));
  max-height: 124vw;
}

@media screen and (orientation: portrait) {
  video {
    object-fit: cover;
    height: calc(100vh - 128px);
    max-height: 124vw;
  }
}
</style>
